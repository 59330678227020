import { Component, OnInit } from '@angular/core';
import { ModalsService } from '../../../_core/services/modals.service';
import { environment } from 'src/environments/environment';
import { EnvironmentNames } from 'src/app/_core/contants/environments';

@Component({
  selector: 'add-charity-modal',
  templateUrl: './add-charity.component.html',
  styleUrls: ['./add-charity.component.scss'],
})
export class AddCharityComponent implements OnInit {
  hasFootNote: boolean;
  hasSecondaryText: boolean;

  private readonly EXCLUDED_ENVIRONMENTS = [EnvironmentNames.CNCF, EnvironmentNames.IGF, EnvironmentNames.SINNGEBER];
  constructor(private modalsService: ModalsService) {}

  ngOnInit(): void {
    this.hasFootNote = !this.EXCLUDED_ENVIRONMENTS.includes(environment.environmentName);
    this.hasSecondaryText = environment.environmentName === EnvironmentNames.CNCF;
  }

  close() {
    this.modalsService.closeModal();
  }
}
